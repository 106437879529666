<template>
  <div>
    <h2 class="mb-4">Статьи</h2>

    <news-filters class="mb-5" :table-params="tableParams" @change-field="changeField" />

    <data-table ref="dataTable" url="news" :headers="headers" :table-params="tableParams">
      <template v-slot:[`item.publishedOn`]="{ item: news }">{{ news.publishedOn | dateTime }}</template>

      <template v-slot:[`item.actions`]="{ item: news }">
        <nobr>
          <v-btn class="mr-2" icon color="accent" @click="getRouteToNews(news)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </nobr>
      </template>
    </data-table>
  </div>
</template>

<script>
import { DateHelper } from "~/core/date";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import NewsFilters from "~/components/news/index/filters";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Заголовок", value: "title" },
        { text: "Дата", value: "publishedOn" },
        { text: "Действия", value: "actions", sortable: false }
      ],

      tableParams: {
        site: "ITS"
      }
    };
  },
  methods: {
    getRouteToNews(news) {
      if (!news.publishedOn) return;

      const formatDate = DateHelper.formatDate(news.publishedOn).split("-");

      this.$router.push({
        name: "news-year-month-day-slug",
        params: { year: formatDate[0], month: formatDate[1], day: formatDate[2], slug: news.slug }
      });
    },

    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    }
  },
  components: {
    NewsFilters
  }
};
</script>
